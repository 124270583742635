@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Color styles */
  --primary: #ffc726;
  --secondary: #101828;
  --error: #fa4d56;
  --grey-900: #101828;
  --grey-700: #344054;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.scrollable-modal {
  height: 40rem;
  overflow-y: scroll;
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner-container > div {
  position: relative; /* Keep spinner centered relative to the container */
}

.overview-container {
  /* display: flex; */
  /* height: calc(100vh - 9rem); */
  /* height: 100vh;
  overflow: hidden; */
}

.custom-wysiwyg {
  /* display: flex; */
  /* height: calc(100vh - 9rem); */
  /* height: 100vh;
  overflow: hidden; */
}

.custom-wysiwyg > .ql-toolbar {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.custom-wysiwyg > .ql-container {
  min-height: 8rem;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
