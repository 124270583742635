.success-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 30px;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  min-width: 400px;
  min-height: 50vh;

  text-align: center;
}
