.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 24px;
  padding-inline: 5%;
  /* background-color: white; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  max-width: 100%;
  margin: 0 auto;
}

.navbar-logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.nav-logo {
  height: 70;
  width: 68;
}
.logo-text {
  width: 117px;
  height: 51px;
  gap: 0px;
  opacity: 0.9;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
  display: inline-block;
}
.logo-text2 {
  width: auto;
  max-width: 240px;
  opacity: 0.9;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
  display: inline-block;
  white-space: normal;
  word-wrap: break-word;
}

.nav-links {
  display: flex;
  gap: 60px;
}

.nav-links a {
  color: black;
  text-decoration: none;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.nav-links a.active {
  border-bottom: 2px solid #ffc726;
  color: black;
  font-weight: bolder;
}

.nav-buttons {
  gap: 10px;
}
.dropdown-toggle-button {
  background-color: #f3f4f6; /* Light background for the button */
  padding: 8px;
  border-radius: 50%; /* Make it circular */
}

.dropdown-menu {
  width: 150px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
}

.dropdown-menu li {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-menu li:hover {
  background-color: #f3f4f6;
}
