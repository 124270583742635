.choose-demo-card {
  width: 100%;
  min-height: 187px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid var(--Hover-Text, #e5e5e5);
  background-color: #ffffff;
  box-shadow: none;
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s ease;
}
.choose-demo-card img {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

.choose-demo-card:hover {
  transform: scale(1.05);
}
