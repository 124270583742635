.choose-demo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
  }
  
  .choose-demo-card {
    width: 320px;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s ease;
  }
  
  .choose-demo-card img {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }
  
  .choose-demo-card h2 {
    font-size: 20px;
    margin-bottom: 20px;
    color: #333333;
    text-align: left;
  }
  
  .choose-demo-card p {
    font-size: 14px;
    color: #666666;
  }
  
  .choose-demo-card:hover {
    transform: scale(1.05);
  }