.sidebar-container {
  display: none;
}

/* .sidebar-container {
  flex: 1;
  width: 20%;
  min-width: 20%;
  max-width: 25%;
  background-color: white;
  height: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
} */

.sidebar.collapsed {
  width: 6rem;
}

.sidebar-header {
  padding: 1.6rem;
  background-color: #f0f0f0;
  border-bottom: 0.1rem solid #ccc;
}

.menu-icon {
  font-size: 2.4rem;
  cursor: pointer;
}

.sidebar-title {
  flex: 1;
  text-align: center;
  font-weight: bold;
  color: #333;
}

.sidebar-content {
  flex: 1;
  padding: 1.5rem 1.5rem 0 1.5rem;
  overflow-y: auto;
}

.menu-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-item {
  /* display: flex;
  align-items: center; */

  padding: 0.75rem;
  cursor: pointer;
  border-radius: 0.8rem; /* Adds border-radius */
  transition: background-color 0.3s ease;
}

/* .menu-item:hover {
  background-color: gainsboro;
} */

.menu-item.selected {
  background-color: #ffc726;
  color: #333;
}

.menu-item span {
  margin-left: 1rem;
}

.sidebar-footer {
  padding: 1.6rem;
  text-align: center;
  font-size: 1.4rem;
  color: #777;
  /* height: 7rem; */
}

@media screen and (min-width: 768px) {
  .sidebar-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border-right: 1px solid var(--Hover-Text, #e5e5e5);

    height: 100vh;
    width: 310px;

    transition: width 0.3s ease;
  }
}
