.register-form {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
  padding: 1rem;
  background-color: #f9fafb; /* Tailwind's bg-gray-50 */
}

.form-header {
  text-align: center;
  margin-bottom: 2rem;
}

.register-card {
  padding-block: 50px;
}

.divider-container {
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
}

.divider {
  flex-grow: 1;
  height: 1px;
  background-color: #d1d5db; /* Tailwind's bg-gray-300 */
}

.divider-text {
  margin: 0 0.5rem;
  color: #6b7280;
  font-size: 0.875rem;
}

.social-login-buttons {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
}

@media (min-width: 640px) {
  .register-form {
    align-items: center;
  }

  .register-card {
    background-color: white;
    border: 1px solid #e5e5e5;
    border-radius: 1.875rem;
    padding: 50px;
  }
}
