.recover-password-card {
    width: 350px;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .recover-password-card img {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }
  
  .recover-password-card h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333333;
  }
  
  .recover-password-card label {
    font-size: 14px;
    color: #666666;
    display: block;
    margin-bottom: 10px;
    
  }
  
  .recover-password-card input {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 14px;
  }
  
  
  
  .recover-password-card button:hover {
    background-color: #FFC726;
  }
  
  