.view-area {
  flex: 3;
  padding: 20px;
  background-color: #fff;
  overflow-y: auto;
}

.cards-container {
  display: flex;
  justify-content: space-around;
  gap: 15px;
  margin-bottom: 40px;
}

.card {
  background-color: white;
  border-radius: 12px;
  width: 22%;
  padding: 20px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-icon {
  background-color: #ddd;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.card-info {
  text-align: center;
}

.card-number {
  font-size: 24px;
  font-weight: bold;
  margin: 5px 0;
}

.card-text {
  font-size: 16px;
  color: #666;
}

.progress-section {
  background-color: white;
  border-radius: 12px;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.progress-section h2 {
  font-size: 22px;
  margin-bottom: 15px;
}
/* @media (max-width: 576px) {
  .cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
} */
