.choose-demo-container {
  display: flex;
  gap: 2rem;
  padding: 2rem;
}
.textStyle {
  color: var(--Active-text, #161616);
  font-family: 'Inter', sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 91.356%;
  letter-spacing: -0.612px;
}
.textStyle2 {
  color: var(--Placeholder-text, #8d8d8d);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
