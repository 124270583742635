.reset-password-card {
    width: 350px;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .reset-password-card h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333333;
  }
  
  .reset-password-card label {
    font-size: 14px;
    color: #666666;
    display: block;
    margin-bottom: 10px;
  }
  
  .password-input-wrapper {
    position: relative;
    margin-bottom: 20px;
  }
  
  input {
    width: 100%;
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .password-toggle-btn {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
  }
  
  .confirm-btn {
    width: 100%;
    padding: 12px;
    background-color: #FFC726;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .confirm-btn:hover {
    background-color: #FFC726;
  }
  