.progress-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    width: 100%;
  }
  
  .step-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    flex-grow: 1;
    position: relative;
  }
  
  .circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #e0e0e0;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    margin-top: 30px;
    z-index: 1; /* Put the circle on top of the line */
  }
  
  .circle.completed {
    background-color: #0c970c; /* Yellow color for completed steps */
  }
  
  .step-label {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 500;
  }
  
  .line {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 5px;
    width: 100%;
    background-color: #e0e0e0;
    
  }
  
  .line.completed {
    background-color: #0c970c; /* Yellow color for completed steps */
  }
  