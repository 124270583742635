.primary {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    background-color: var(--primary); 
    color: black; 
    font-size: 14px; 
    
  }
  .secondary {
    background-color: var(--secondary);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 14px;

  }
  .outline {
    
      background-color: transparent;
      border: 1px solid #42BE65; 
      color: #101828; 
      padding: 6px 12px; 
      border-radius: 8px; 
      font-family: 'Inter', sans-serif;
      font-size: 14px; 
      cursor: pointer; 
      transition: all 0.3s ease; 
      outline-style: none !important;
    
  }
    
  .outline:hover {
    /* background-color: #42BE65; 
    color: white;  */
  }
  
  
  .danger {
    background-color: red;
    color: white;
  }
  
  .disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  .icon-left, .icon-right {
    margin: 0 5px;
  }
  
  .loader {
    font-size: 12px;
    color: gray;
  }
  
  