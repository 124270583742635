.swiper-button-next,
.swiper-button-prev {
  width: '24px';
  height: '24px';
  color: 'white';
}
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px; /* Adjust arrow icon size */
}
