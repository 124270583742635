.login-form {
  display: flex;
  flex-direction: column;

  /* justify-content: center; */
  min-height: 100vh;
  padding: 1rem;
  background-color: #f9fafb; /* Tailwind's bg-gray-50 */
}

.form-header {
  text-align: center;
  margin-bottom: 2rem;
}

.login-card {
  padding-block: 50px;
  /* padding-inline: 20px; */
}

.divider-container {
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
}

.divider {
  flex-grow: 1;
  height: 1px;
  background-color: #d1d5db; /* Tailwind's bg-gray-300 */
}

.divider-text {
  margin: 0 0.5rem;
  color: #6b7280; /* Tailwind's text-gray-500 */
  font-size: 0.75rem; /* Tailwind's text-sm */
  font-weight: 600;
}

.copyright {
  margin-top: 1.5rem;
  text-align: center;
  color: #6b7280; /* Tailwind's text-gray-500 */
  font-size: 0.75; /* Tailwind's text-xs */
}

.social-login-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
}

@media (min-width: 640px) {
  .login-form {
    align-items: center;
  }

  .login-card {
    background-color: white;
    border: 1px solid #e5e5e5;
    border-radius: 1.875rem;
    padding: 50px;
  }
}
