.otp-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 1rem;
  background-color: #f9fafb;
}

.form-header {
  text-align: center;
  margin-bottom: 2rem;
}

.otp-card {
  background-color: white;
  border-radius: 1.875rem;
  border: 1px solid #e5e5e5;
  padding: 3.125rem;
  width: 100%;
  max-width: 32rem;
}

.otp-input-container {
  display: flex;
  justify-content: space-between;
  column-gap: 1rem;
  margin: 2rem 0 1.5rem;
}

.otp-input {
  width: 3.5rem;
  height: 3.5rem;
  font-size: 2rem;
  text-align: center;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
}

.request-new-code {
  /* color: #6b7280; */
  font-size: 0.875rem;
  cursor: pointer;
  text-decoration: underline;
  padding-block: 10px;
}
